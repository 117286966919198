import { useLocale } from '~/hooks/useLocale'
import { registerSuperProperties } from '~/utils/tracking'
import { useIsomorphicLayoutEffect } from '@zoi/react-hooks'

export function useTrackingRegister() {
  const locale = useLocale()

  useIsomorphicLayoutEffect(() => {
    registerSuperProperties({
      locale
    })
  }, [locale])
}
