import React from 'react'
import * as R from 'remeda'
import { matchIsBrowser } from '~/helpers/navigator'
import { useEvent } from '@zoi/react-hooks'

export type ScriptProps = React.ComponentPropsWithoutRef<'script'> &
  ({ src: string; innerHtml?: never } | { innerHtml: string; src?: never })

function matchIsScriptExists(source: ScriptProps['src']) {
  if (source && matchIsBrowser()) {
    return Boolean(document.querySelector(`script[src="${source}"]`))
  }

  return false
}

const Script = ({ src, innerHtml, ...scriptProps }: ScriptProps) => {
  const getLastScriptProps = useEvent(() => {
    return scriptProps
  })

  React.useEffect(() => {
    if (matchIsScriptExists(src)) {
      return
    }

    const scriptElement = document.createElement('script')

    if (src) {
      scriptElement.src = src
    }

    if (innerHtml) {
      scriptElement.innerHTML = innerHtml
    }

    R.forEachObj(getLastScriptProps(), (value, key) => {
      scriptElement.setAttribute(key, value)
    })

    document.body.appendChild(scriptElement)
  }, [src, getLastScriptProps, innerHtml])

  return null
}

export default Script
