import React from 'react'
import Script from '~/components/Script'
import { AXEPTIO_CLIENT_ID } from '~/constants/env'
import { useLocale } from '~/hooks/useLocale'
import type { ValuesByLocale } from '~/i18n/config'
import { optInTracking, optOutTracking } from '~/utils/tracking'
import { useIsomorphicLayoutEffect } from '@zoi/react-hooks'

const COOKIES_VERSION_BY_LOCALE: ValuesByLocale<string> = {
  fr: 'zoī-fr',
  en: 'zoī-en'
}

const Axeptio = () => {
  const locale = useLocale()
  const cookiesVersion = COOKIES_VERSION_BY_LOCALE[locale]

  useIsomorphicLayoutEffect(() => {
    window.axeptioSettings = {
      clientId: AXEPTIO_CLIENT_ID,
      cookiesVersion,
      googleConsentMode: {
        default: {
          analytics_storage: 'denied',
          ad_storage: 'denied',
          ad_user_data: 'denied',
          ad_personalization: 'denied',
          wait_for_update: 500
        }
      },
      userCookiesDuration: 365
    }
    window._axcb = [
      (axeptio: AxeptioCallback) => {
        axeptio.on('cookies:complete', (choices) => {
          if (choices.Mixpanel) {
            optInTracking()
          } else {
            optOutTracking()
          }
        })
      }
    ]
  })

  return <Script async src="//static.axept.io/sdk-slim.js" />
}

export default Axeptio
