import React from 'react'
import Script from '~/components/Script'

let currDataLayerName: string | undefined

export type GoogleTagManagerProps = {
  gtmId: string
  dataLayer?: string[]
  dataLayerName?: string
  auth?: string
  preview?: string
}

const GoogleTagManager = ({
  gtmId,
  dataLayer = undefined,
  dataLayerName = 'dataLayer',
  auth = undefined,
  preview = undefined
}: GoogleTagManagerProps) => {
  if (currDataLayerName === undefined) {
    currDataLayerName = dataLayerName
  }

  const gtmLayer = dataLayerName !== 'dataLayer' ? `&l=${dataLayerName}` : ''
  const gtmAuth = auth ? `&gtm_auth=${auth}` : ''
  const gtmPreview = preview ? `&gtm_preview=${preview}&gtm_cookies_win=x` : ''

  return (
    <>
      <Script
        innerHtml={`(function(w,l){
        w[l]=w[l]||[];
        w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
        ${dataLayer ? `w[l].push(${JSON.stringify(dataLayer)})` : ''}
      })(window,'${dataLayerName}');`}
      />
      <Script
        src={`https://www.googletagmanager.com/gtm.js?id=${gtmId}${gtmLayer}${gtmAuth}${gtmPreview}`}
      />
    </>
  )
}

export default GoogleTagManager
